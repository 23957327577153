import React from "react";
import "./style.css";
import LeftSide from "./components/LeftSide";
import RightSide from "./components/RightSide";

const SecondStep = () => {
  return (
    <div className="container-fluid dim-bg main-quote-page">
      <div className="row">
        <div className="col-lg-6">
          <LeftSide />

        </div>
        <div className="col-lg-6">
          <RightSide />

        </div>
      </div>
    </div>
  );
};

export default SecondStep;
