import { configureStore } from "@reduxjs/toolkit";
import bookingSlice from "../features/bookingSlice/bookingSlice";
import storage from "redux-persist/lib/storage"; // Uses localStorage
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";

// Configure persist settings
const persistConfig = {
  key: "root",
  storage,
};

// Combine reducers and persist
const rootReducer = combineReducers({
  booking: bookingSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create Redux store with persisted state
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

// Persist store
export const persistor = persistStore(store);