import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import loader from "../../assets/images/loader.gif";
import { FaUser, FaEnvelope , FaPhoneAlt , FaPlaneDeparture ,FaPlane } from "react-icons/fa";

import {
    removeCard,
    setBookingId,
} from "../../features/bookingSlice/bookingSlice";
import CardModal from "./CardModal";
import { confirmBooking } from "../../api/api.js";

function Payment() {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countryCode, setCountryCode] = useState("+1"); // Default country code is USA

    const card = useSelector((state) => state.booking.card);
    const fleet_record_with_rate_id = useSelector(
        (state) => state.booking.fleet_record_with_rate_id
    );
    const maps_record_id = useSelector((state) => state.booking.maps_record_id);
    const quote_info_record_id = useSelector(
        (state) => state.booking.quote_info_record_id
    );
    const selected_vehicle_id = useSelector(
        (state) => state.booking.selected_vehicle_id
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleConfirm = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData(e.target);

        // Concatenate the selected country code with the phone number
        const phone = `${countryCode}${formData.get("phone")}`;

        const bookingData = {
            name: formData.get("full-name"),
            email: formData.get("email"),
            phone: phone, // Use the concatenated phone number with country code
            flight_name: formData.get("flight-name"),
            flight_number: formData.get("flight-number"),
            maps_record_id,
            quote_info_record_id,
            selected_vehicle_record_id: selected_vehicle_id,
            fleet_record_with_rate_id,
            payment_method: "Credit card ($1)",
            payment_response_data: card
                ? {
                    cardNumber: card.cardNumber,
                    expireDate: card.expiry,
                    cvc: card.cvc,
                    holderName: card.holderName,
                }
                : {},
        };

        try {
            const response = await confirmBooking(bookingData);
            console.log("Confirm booking:", response);

            if (response.status === 1) {
                const booking_id = response?.data.booking_info_record_id;
                dispatch(setBookingId(booking_id));
                navigate("/order-complete");
            }
        } catch (error) {
            console.error("Error confirming booking:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form
            onSubmit={ handleConfirm }
            className="w-full bg-white rounded-lg"
        >
            { loading && (
                <div className="full-page-loader">
                    <img src={ loader } width="200px" alt="Loading..." />
                </div>
            ) }
            <div className="container-fluid payment-fields p-0">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <div className="field-icon"><FaUser /></div>
                            <input type="text" className="field-style form-control" name="full-name" required placeholder="Name"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="field-icon"><FaEnvelope /></div>
                            <input type="email" className="field-style form-control" name="email" required placeholder="Email"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="flex items-center">
                                <div className="field-icon"><FaPhoneAlt  /></div>
                                <div className="tel-number-field d-flex">
                                    {/* Country Code Dropdown */ }
                                    <select
                                        value={ countryCode }
                                        onChange={ (e) => setCountryCode(e.target.value) }
                                        className="field-style form-control"
                                    >
                                        <option value="+1">+1 (USA)</option>
                                        <option value="+44">+44 (UK)</option>
                                        <option value="+91">+91 (India)</option>
                                        <option value="+61">+61 (Australia)</option>
                                        <option value="+33">+33 (France)</option>
                                        <option value="+49">+49 (Germany)</option>
                                        <option value="+81">+81 (Japan)</option>
                                        <option value="+86">+86 (China)</option>
                                        <option value="+55">+55 (Brazil)</option>
                                        <option value="+7">+7 (Russia)</option>
                                        <option value="+34">+34 (Spain)</option>
                                        <option value="+39">+39 (Italy)</option>
                                        <option value="+27">+27 (South Africa)</option>
                                        {/* Add more country codes as needed */ }
                                    </select>
                                    {/* Phone Number Input */ }
                                    <input
                                        type="tel"
                                        className="field-style form-control"
                                        name="phone"
                                        placeholder="(201) 555-0123"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="field-icon"><FaPlaneDeparture  /></div>
                            <input type="text" className="field-style form-control" name="flight-name" placeholder="Flight Name" required/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="field-icon"><FaPlane /></div>
                            <input type="text" className="field-style form-control" name="flight-number" required placeholder="Flight Number"/>
                        </div>
                    </div>
                </div>
                <div className="pt-4 ">
                    <h3 className="pay-with-card">Pay With Card</h3>
                    <h6 className="text-lg font-semibold mb-4">
                        <i>Note :- (We only charge $1 for confirm booking.)</i>
                    </h6>

                    { card ? (
                        <div className="flex justify-between items-center bg-gray-100 p-4 rounded-md">
                            <span className="text-lg font-bold">
                                Card: **** **** **** { card.last4 }
                            </span>
                            <button
                                onClick={ () => dispatch(removeCard()) }
                                className="bg-red-500 text-white px-4 py-2 rounded-md"
                            >
                                Remove
                            </button>
                        </div>
                    ) : (
                        <button
                            type="button"
                            className="add-card-btn"
                            onClick={ () => setIsModalOpen(true) }
                        >
                            + Add Card
                        </button>
                    ) }
                </div>
                <button
                    type="submit"
                    className="confirm-order"
                >
                    CONFIRMED
                </button>
            </div>
            <CardModal isOpen={ isModalOpen } onClose={ () => setIsModalOpen(false) } />
        </form>
    );
}

export default Payment;
