import api from './axiosConfig';

async function fetchData() {
  try {
    const response = await api.get('/api/quote');
    console.log(response.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
export const fetchQuote = async (data) => {
  try {
    const response = await api.post("/api/quote", data);
    return response.data; 
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; 
  }
};

export const bookingInfo = async (data) => {
  try {
    const response = await api.post("/api/quote/booking-info", data);
    return response.data; 
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; 
  }
};
export const confirmBooking = async (data) => {
  try {
    const response = await api.post("/api/quote/confirm", data);
    return response.data; 
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; 
  }
};
export const finishBooking = async (data) => {
  try {
    const response = await api.post("/api/quote/finish", data);
    return response.data; 
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; 
  }
};
