import React from "react";
import { FiChevronLeft } from "react-icons/fi";
import { FaRegMap , FaRoad, FaCalendarAlt, FaCar  } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function LeftSide() {
  const navigate = useNavigate();

  // Extracting data from Redux state
  const {
    pickup,
    dropoff,
    distance,
    duration,
    time,
    date,
    passenger,
    luggage,
    booster,
    regular,
    infant,
    fleet_selection,
  } = useSelector((state) => state.booking);

  return (
    <div className="journey-info">
        <div
          className="back-btn"
          onClick={() => navigate("/")}
        >
          <FiChevronLeft size={20} />
          <button>Back</button>
        </div>

        <h1>
          Summary
          <span>
            {fleet_selection?.fare_details?.total_fare !== undefined
              ? fleet_selection.fare_details.total_fare.toFixed(2)
              : "N/A"}
          </span>
        </h1>

        <div>
          {/* Journey Information */}
          {(pickup?.address || dropoff?.address) && (
              <div className="left-side-card">
                <FaRegMap  className="mb-3" />
                <div>
                  <h4 className="card-heading1">Journey Information</h4>
                  <p className="mb-0">{pickup?.address || "N/A"}</p>
                  <span className="font-bold">TO</span>
                  <p>{dropoff?.address || "N/A"}</p>
                </div>
              </div>
          )}

          {/* Coverage */}
          {(distance || duration) && (
            <div className="left-side-card">
              <FaRoad />
              <div>
                <h4 className="card-heading1">Coverage</h4>
                <p>
                  {distance || "N/A"} : {duration || "N/A"}
                </p>
              </div>
            </div>
          )}

          {/* Pickup Date and Time */}
          {(date || time || passenger || luggage) && (
            <div className="left-side-card">
              <FaCalendarAlt />
              <div className="font-semibold">
                <h4 className="card-heading1">Pickup Date &amp; Time</h4>
                <p className="mb-0">{date || "N/A"}</p>
                <p className="mb-0">{time || "N/A"}</p>
                <p className="mb-0">{`Passenger: ${passenger || 0}, Luggage: ${
                  luggage || 0
                }`}</p>
              </div>
            </div>
          )}

          {/* Choose Vehicles */}
          {fleet_selection?.name && (
            <div className="flex gap-3 items-center mt-3 pb-3 summary-card">
              <FaCar />
              <div className="font-semibold">
                <p className="font-bold text-xl mb-0">Choose Vehicles</p>
                <p className="mb-0">{fleet_selection.name}</p>
                {fleet_selection?.info?.img_link && (
                  <img
                    width="100px"
                    src={fleet_selection.info.img_link}
                    alt="Fleet"
                  />
                )}
              </div>
            </div>
          )}

          {/* Additional Information */}
          {(booster || regular || infant) && (
            <div className="mt-4">
              <p className="text-xl font-bold">Additional Information</p>
              {booster ? (
                <p className="mb-0 font-semibold">{`Booster: ${booster}`}</p>
              ) : null}
              {regular ? (
                <p className="mb-0 font-semibold">{`Regular: ${regular}`}</p>
              ) : null}
              {infant ? (
                <p className="mb-0 font-semibold">{`Infant: ${infant}`}</p>
              ) : null}
            </div>
          )}
        </div>
    </div>
  );
}

export default LeftSide;
