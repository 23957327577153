import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCard } from "../../features/bookingSlice/bookingSlice";
import { IoMdClose } from "react-icons/io";
import { FaCreditCard, FaCalendarDay, FaUser } from "react-icons/fa";
import { MdOutlineQrCode } from "react-icons/md";

const CardModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [holderName, setHolderName] = useState("");
  const [errors, setErrors] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
    holderName: "",
  });

  // Field-specific validation
  const validateField = (fieldName, value) => {
    if (fieldName === "cardNumber") {
      const cardRegex = /^\d{16}$/; // 16-digit card number
      if (!cardRegex.test(value.replace(/\s/g, ""))) {
        return "Invalid card number.";
      }
    } else if (fieldName === "expiry") {
      const expiryRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;
      if (!expiryRegex.test(value)) {
        return "Invalid expiry date (MM/YYYY).";
      }
      // Check if expiry date is in the past
      const [month, year] = value.split("/");
      const expMonth = parseInt(month, 10);
      const expYear = parseInt(year, 10);
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();
      if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
        return "Expiry date cannot be in the past.";
      }
    } else if (fieldName === "cvc") {
      const cvcRegex = /^\d{3,4}$/; // 3 or 4-digit CVC
      if (!cvcRegex.test(value)) {
        return "Invalid CVC.";
      }
    } else if (fieldName === "holderName") {
      if (value.trim() === "") {
        return "Holder name cannot be empty.";
      }
    }
    return "";
  };

  const handleSubmit = () => {
    // Validate all fields on submit
    const cardError = validateField("cardNumber", cardNumber);
    const expiryError = validateField("expiry", expiry);
    const cvcError = validateField("cvc", cvc);
    const holderNameError = validateField("holderName", holderName);

    if (cardError || expiryError || cvcError || holderNameError) {
      setErrors({
        cardNumber: cardError,
        expiry: expiryError,
        cvc: cvcError,
        holderName: holderNameError,
      });
      return;
    }

    dispatch(
      addCard({
        cardNumber,
        expiry,
        cvc,
        holderName,
        last4: cardNumber.slice(-4),
      })
    );
    onClose();
  };

  // Check if form is valid (all required fields are filled and no error messages exist)
  const isFormValid =
    cardNumber &&
    expiry &&
    cvc &&
    holderName &&
    !errors.cardNumber &&
    !errors.expiry &&
    !errors.cvc &&
    !errors.holderName;

  return (
    isOpen && (
      <div className="fixed inset-0 bg-gray-900/48 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-3 rounded-md shadow-lg w-96 modal-card">
          <button onClick={onClose} className="modal-close-btn">
            <IoMdClose />
          </button>
          <h2 className="modal-heading">Enter Card Details</h2>
          <div className="row">
            <div className="col-12">
              <div
                className={`form-group ${
                  errors.cardNumber ? "border-2 border-red-500" : ""
                }`}
              >
                <div className="field-icon">
                  <FaCreditCard />
                </div>
                <input
                  type="text"
                  placeholder="Card Number"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  onBlur={() => {
                    const errorMsg = validateField("cardNumber", cardNumber);
                    setErrors((prev) => ({ ...prev, cardNumber: errorMsg }));
                  }}
                  className="field-style form-control"
                  maxLength={16}
                />
              </div>
              {errors.cardNumber && (
                <p className="text-red-500 text-sm mt-0 mb-0">{errors.cardNumber}</p>
              )}
            </div>
            <div className="col-6">
              <div
                className={`form-group ${
                  errors.expiry ? "border-2 border-red-500" : ""
                }`}
              >
                <div className="field-icon">
                  <FaCalendarDay />
                </div>
                <input
                  type="text"
                  placeholder="MM/YYYY"
                  value={expiry}
                  onChange={(e) => setExpiry(e.target.value)}
                  onBlur={() => {
                    const errorMsg = validateField("expiry", expiry);
                    setErrors((prev) => ({ ...prev, expiry: errorMsg }));
                  }}
                  className="field-style form-control"
                />
              </div>
              {errors.expiry && (
                <p className="text-red-500 text-sm mt-0 mb-0">{errors.expiry}</p>
              )}
            </div>
            <div className="col-6">
              <div
                className={`form-group ${
                  errors.cvc ? "border-2 border-red-500" : ""
                }`}
              >
                <div className="field-icon">
                  <MdOutlineQrCode />
                </div>
                <input
                  type="text"
                  placeholder="CVC"
                  value={cvc}
                  onChange={(e) => setCvc(e.target.value)}
                  onBlur={() => {
                    const errorMsg = validateField("cvc", cvc);
                    setErrors((prev) => ({ ...prev, cvc: errorMsg }));
                  }}
                  className="field-style form-control"
                  maxLength={4}
                />
              </div>
              {errors.cvc && (
                <p className="text-red-500 text-sm mt-0 mb-0">{errors.cvc}</p>
              )}
            </div>
            <div className="col-12">
              <div
                className={`form-group ${
                  errors.holderName ? "border-2 border-red-500" : ""
                }`}
              >
                <div className="field-icon">
                  <FaUser />
                </div>
                <input
                  type="text"
                  placeholder="Holder Name"
                  value={holderName}
                  onChange={(e) => setHolderName(e.target.value)}
                  onBlur={() => {
                    const errorMsg = validateField("holderName", holderName);
                    setErrors((prev) => ({ ...prev, holderName: errorMsg }));
                  }}
                  className="field-style form-control"
                />
              </div>
              {errors.holderName && (
                <p className="text-red-500 text-sm mt-0 mb-0">{errors.holderName}</p>
              )}
            </div>
            <div className="col-12">
              <div className="form-group">
                <button 
                  onClick={handleSubmit} 
                  className="add-card-btn-modal"
                  disabled={!isFormValid}
                >
                  Add Card
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CardModal;
