import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCalendar, FaUser, FaSuitcase } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import { fetchQuote } from "../../api/api.js";
import loader from "../../assets/images/loader.gif";
import {
  setPassenger,
  setLuggage,
  setBooster,
  setRegular,
  setInfant,
  setTime,
  setDate,
} from "../../features/bookingSlice/bookingSlice";

function RightSide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookingData = useSelector((state) => state.booking);
  const { passenger, date, time, luggage, booster, regular, infant } = bookingData;

  // Utility functions to convert date/time formats
  const convertToISOFormat = (date) => {
    if (!date) return "";
    const parts = date.split("/");
    if (parts.length !== 3) return "";
    const [month, day, year] = parts;
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const convertTo24HourFormat = (time) => {
    if (!time) return "";
    let [hours, minutes] = time.split(":");
    let amPm = minutes.slice(-2);
    minutes = minutes.slice(0, -2);
    hours = parseInt(hours, 10);
    if (amPm.toUpperCase() === "PM" && hours !== 12) hours += 12;
    if (amPm.toUpperCase() === "AM" && hours === 12) hours = 0;
    return `${String(hours).padStart(2, "0")}:${minutes}`;
  };

  const [isOn, setIsOn] = useState(false);
  const [localDate, setLocalDate] = useState(convertToISOFormat(date));
  const [localTime, setLocalTime] = useState(convertTo24HourFormat(time));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleToggle = () => {
    setIsOn((prev) => !prev);
  };

  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    setLocalDate(inputDate);
    const [year, month, day] = inputDate.split("-");
    const formattedDate = `${month}/${day}/${year}`;
    dispatch(setDate(formattedDate));
  };

  const handleTimeChange = (e) => {
    const inputTime = e.target.value;
    setLocalTime(inputTime);
    let [hours, minutes] = inputTime.split(":");
    hours = parseInt(hours, 10);
    const period = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    const formattedTime = `${hours}:${minutes}${period}`;
    dispatch(setTime(formattedTime));
  };

  const isFormValid = date && time && passenger && luggage;

  const handleNext = async () => {
    if (!isFormValid) return;
    setLoading(true);
    setError(null);

    // Build the payload for the quote API request
    const requestData = {
      booster_seat: bookingData.booster || "0",
      domain_id: bookingData.domain_id || "",
      end: bookingData.dropoff?.address || "",
      end_lat: bookingData.dropoff?.coordinates?.lat || 0,
      end_lng: bookingData.dropoff?.coordinates?.lng || 0,
      infant_seat: bookingData.infant || "0",
      luggage: bookingData.luggage || "0",
      passengers: bookingData.passenger || "1",
      pick_date: bookingData.date || "",
      pick_time: bookingData.time || "",
      regular_seat: bookingData.regular || "0",
      service_type: bookingData.service_type || "",
      start: bookingData.pickup?.address || "",
      start_lat: bookingData.pickup?.coordinates?.lat || 0,
      start_lng: bookingData.pickup?.coordinates?.lng || 0,
    };

    try {
      const response = await fetchQuote(requestData);
      console.log("Quote Response:", response);
      navigate("/vehicleselection");
    } catch (err) {
      console.error("Error fetching quote:", err);
      // Set error state to the error message string
      setError(err.response.data.message || "An error occurred while fetching the quote.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="right-form-2nd">
      {loading && (
        <div className="loader-overlay">
          <img src={loader} width="200px" alt="Loading..." />
        </div>
      )}

      {error && (
        <div className="error-popup">
          <div className="popup-content">
            <p>{error}</p>
            <button onClick={() => setError(null)}>Close</button>
          </div>
        </div>
      )}

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <div className="field-icon">
                <FaCalendar />
              </div>
              <input
                type="date"
                id="date"
                value={localDate}
                className="field-style form-control"
                onChange={handleDateChange}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="field-icon">
                <MdAccessTimeFilled />
              </div>
              <input
                type="time"
                id="time"
                value={localTime}
                placeholder="hh:mm AM/PM"
                className="field-style form-control"
                onChange={handleTimeChange}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="field-icon">
                <FaUser />
              </div>
              <select
                className="field-style form-control"
                id="passengers"
                value={passenger}
                onChange={(e) => dispatch(setPassenger(e.target.value))}
                required
              >
                <option value="">Passengers</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="field-icon">
                <FaSuitcase />
              </div>
              <select
                className="field-style form-control"
                id="luggage"
                value={luggage}
                onChange={(e) => dispatch(setLuggage(e.target.value))}
                required
              >
                <option value="">Luggage</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <span>Add Extras</span>
          <div
            className={`relative w-12 h-6 ${isOn ? "bg-green-700" : "bg-gray-500"} rounded-full p-1 transition-colors duration-300 cursor-pointer`}
            onClick={handleToggle}
          >
            <div
              className={`bg-white w-4 h-4 rounded-full transition-transform duration-300 ${isOn ? "transform translate-x-6" : ""}`}
            ></div>
          </div>
        </div>

        {isOn && (
          <div className="w-full flex justify-between mt-4 gap-2">
            <select
              className="border-2 p-2 w-1/3"
              id="booster"
              value={booster}
              onChange={(e) => dispatch(setBooster(e.target.value))}
            >
              <option value="">Booster</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <select
              className="border-2 p-2 w-1/3"
              id="regular"
              value={regular}
              onChange={(e) => dispatch(setRegular(e.target.value))}
            >
              <option value="">Regular</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <select
              className="border-2 p-2 w-1/3"
              id="infant"
              value={infant}
              onChange={(e) => dispatch(setInfant(e.target.value))}
            >
              <option value="">Infant</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
        )}
      </div>

      <div className="fix-btn-bg bg-success">
        <div className="w-full flex justify-between mt-4 mb-1">
          <button
            className={`btn-main m-auto bg-transparent border-0 py-3 outline-0 fw-bold text-white rounded-md w-100 ${!isFormValid || loading ? "bg-gray-400 cursor-not-allowed" : ""}`}
            onClick={handleNext}
            disabled={!isFormValid || loading}
          >
            Next
          </button>
        </div>
      </div>

      <style jsx>{`
        .loader-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .error-popup {
          position: fixed;
          top: 20%;
          left: 50%;
          transform: translateX(-50%);
          background: #fff;
          border: 1px solid #f00;
          padding: 20px;
          z-index: 1001;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
        .error-popup .popup-content {
          text-align: center;
        }
        .error-popup button {
          margin-top: 10px;
          padding: 6px 12px;
          background: #229558;
          border: none;
          color: #fff;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
}

export default RightSide;
