import React, { useState } from 'react';
import './common.css';
import logo from '../../assets/images/logo.png';
import { FaBell } from 'react-icons/fa';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

// import logo '';
<style>

</style>
const Navbar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const toggleMenu = () => {
        setIsOpen((prev) => !prev); // Toggles the state correctly
    };
    return (
        <div className="nav-main">
            {/* <nav className="navbar">
                <div className="logo">
                    <img src={ logo } alt='Logo' />
                </div>
                <ul className="nav">
                    <li><a href="/">Dashboard</a></li>
                    <li><a href="/">Request Ride</a></li>
                    <li><a href="/">Scheduled</a></li>
                    <li><a href="/">History</a></li>
                    <li><a href="/">FAQ</a></li>
                    <li><a href="/">Support</a></li>
                    <li><a href="/">Account</a></li>
                </ul>
                <div className='notification'>
                    <FaBell size={ 20 } color="white" />
                </div>
            </nav> */}
            <nav className="navbar">
                <div className="nav-container">
                    <div className="logo-content d-flex align-items-center">
                        <a className="logo">
                            <img src={ logo } alt='Logo' />
                        </a>
                        {/* Navigation links */ }
                        <ul className={ `nav ${isOpen ? "" : "show"}` }>
                            <li><a href="/">Dashboard</a></li>
                            <li><a href="/">Request Ride</a></li>
                            <li><a href="/">Scheduled</a></li>
                            <li><a href="/">History</a></li>
                            <li><a href="/">FAQ</a></li>
                            <li><a href="/">Support</a></li>
                            <li><a href="/">Account</a></li>
                        </ul>
                    </div>
                    <div className="main-container-notification d-flex align-items-center gap-2">
                        <div className='notification'>
                            <FaBell size={ 20 } color="white" />
                        </div>
                        {/* Toggle button */ }
                        <button className="toggle-btn" onClick={ toggleMenu }>
                            <FontAwesomeIcon icon={ isOpen ? faBars : faTimes } />
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
