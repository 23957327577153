import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  service_type: "from_airport",
  pickup: "Boston Logan International Airport",
  dropoff: "Boston Logan International Airport",
  distance: null,
  duration: null,
  coverage: null,
  date: null,
  time: null,
  passenger: 1,
  luggage: 1,
  booster: null,
  regular: null,
  infant: null,
  fleet_id: null,
  fleet_record_with_rate_id: null,
  maps_record_id: null,
  quote_info_record_id: null,
  selected_vehicle_id: null,
  fleet_selection: null,
  card: null,
  booking_info: null,
  booking_id: null,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setServiceType: (state, action) => {
      state.service_type = action.payload;
    },
    setPickup: (state, action) => {
      state.pickup = action.payload;
    },
    setDropoff: (state, action) => {
      state.dropoff = action.payload;
    },
    setDistance: (state, action) => {
      state.distance = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setCoverage: (state, action) => {
      state.coverage = action.payload;
    },
    setPassenger: (state, action) => {
      state.passenger = action.payload;
    },
    setLuggage: (state, action) => {
      state.luggage = action.payload;
    },
    setBooster: (state, action) => {
      state.booster = action.payload;
    },
    setRegular: (state, action) => {
      state.regular = action.payload;
    },
    setInfant: (state, action) => {
      state.infant = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    selectFleet: (state, action) => {
      state.fleet_id = action.payload;
    },
    setSelectedVehicleId: (state, action) => {
      state.selected_vehicle_id = action.payload;
    },

    setFleetRecordWithRateId: (state, action) => {
      state.fleet_record_with_rate_id = action.payload;
    },
    setMapsRecordId: (state, action) => {
      state.maps_record_id = action.payload;
    },
    setQuoteInfoRecordId: (state, action) => {
      state.quote_info_record_id = action.payload;
    },
    setFleetSelection: (state, action) => {
      state.fleet_selection = action.payload;
    },
    addCard: (state, action) => {
      state.card = action.payload;
    },
    addBookingInfo: (state, action) => {
      state.booking_info = action.payload;
    },
    removeCard: (state) => {
      state.card = null;
    },
    setBookingId: (state, action) => {
      state.booking_id = action.payload;
    },
    resetBooking: () => initialState,
  },
});

export const {
  setServiceType,
  setPickup,
  setDropoff,
  setDistance,
  setDuration,
  setCoverage,
  setPassenger,
  setLuggage,
  setBooster,
  setRegular,
  setInfant,
  setDate,
  setTime,
  selectFleet,
  setSelectedVehicleId,
  setFleetRecordWithRateId,
  setMapsRecordId,
  setQuoteInfoRecordId,
  setFleetSelection,
  setBookingInfo,
  addCard,
  removeCard,
  setBookingId,
} = bookingSlice.actions;
export const { resetBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
