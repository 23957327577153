import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import NotFoundPage from "./pages/NotFoundPage";
import Navbar from "./pages/components/Navbar";
import FirstStep from "./pages/FirstStep";
import SecondStep from "./pages/SecondStep";
import ThirdStep from "./pages/ThirdStep";
import FourthStep from "./pages/FourthStep";
import ThankYouPage from "./pages/ThankYouPage";
import QuotePage from "./pages/QuotePage";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />}>
          <Route index element={<FirstStep />} />
          <Route path="calender" element={<SecondStep />} />
          <Route path="quote" element={<QuotePage />} />
          <Route path="vehicleselection" element={<ThirdStep />} />
          <Route path="vehicleextra" element={<FourthStep />} />
          <Route path="order-complete" element={<ThankYouPage />} />
        </Route>
        <Route path="/about" element={<AboutPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
