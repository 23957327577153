import React from "react";
import "./style.css";
import LeftSide from "./components/LeftSide";
import Payment from "./components/Payment.jsx";

const FourthStep = () => {
  return (
    <div className="container-fluid dim-bg main-quote-page">
      <div className="row flex min-h-[80vh] w-full shadow-xl p-6 bg-white">
      <div className="col-lg-6">
        <LeftSide />
      </div>
      <div className="col-lg-6">
        <Payment />
      </div>
      </div>
    </div>
  );
};

export default FourthStep;
