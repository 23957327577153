import React, { useState, useEffect, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { FaCircle } from "react-icons/fa";
import Map from "./components/Map"; // Ensure this component handles direction rendering
import "./style.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  setPickup,
  setDropoff,
  setDistance,
  setDuration,
  setServiceType,
} from "../features/bookingSlice/bookingSlice";

const FirstStep = () => {
  const navigate = useNavigate();

  // Fixed input value for Boston Logan Airport
  const fixedInputValue = "Boston Logan International Airport";
  const fixedCoordinates = { lat: 42.3656, lng: -71.0096 };

  const [direction, setDirection] = useState(null);
  const selected = useSelector((state) => state.booking.service_type);

  // Redux state
  const { pickup, dropoff, distance, duration } = useSelector(
    (state) => state.booking
  );
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(setServiceType(e.target.value));
  };
  // Autocomplete refs
  const autocompletePickup = useRef(null);
  const autocompleteDropoff = useRef(null);

  // Update Redux with fixed values when selection changes
  useEffect(() => {
    if (selected === "from_airport") {
      dispatch(setDropoff(""));
      dispatch(
        setPickup({ address: fixedInputValue, coordinates: fixedCoordinates })
      );
    } else {
      dispatch(setPickup(""));
      dispatch(
        setDropoff({ address: fixedInputValue, coordinates: fixedCoordinates })
      );
    }
  }, [selected]);

  // Function to extract address and coordinates
  const handlePlaceChanged = (ref, actionCreator) => {
    if (ref.current) {
      const place = ref.current.getPlace();
      if (place && place.formatted_address && place.geometry) {
        const location = place.geometry.location;
        dispatch(
          actionCreator({
            address: place.formatted_address,
            coordinates: { lat: location.lat(), lng: location.lng() },
          })
        );
      } else {
        console.error("Invalid place selected.");
      }
    }
  };

  // Prevent form submission on Enter key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  // Calculate directions when both pickup and dropoff are set
  useEffect(() => {
    if (pickup?.coordinates && dropoff?.coordinates) {
      const calculateDirection = async () => {
        try {
          const directionsService = new window.google.maps.DirectionsService();
          const result = await directionsService.route({
            origin: pickup.coordinates,
            destination: dropoff.coordinates,
            travelMode: window.google.maps.TravelMode.DRIVING,
          });

          if (result.status === "OK") {
            setDirection(result);
            dispatch(setDistance(result.routes[0].legs[0].distance.text));
            dispatch(setDuration(result.routes[0].legs[0].duration.text));
          } else {
            console.error("Directions request failed due to:", result.status);
          }
        } catch (error) {
          console.error(
            "Error calculating directions:",
            error.message || error
          );
        }
      };

      calculateDirection();
    }
  }, [pickup, dropoff]);

  return (
    <div className="container-fluid dim-bg main-quote-page">
      <div className="row">
        <div className="col-lg-5">
          <div className="floating-sidebar">
            <div className="sidebar-booking">
              <form>
                <div className="from-to">
                  <div className="from">
                    <input
                      type="radio"
                      id="from_airport"
                      name="service_type"
                      value="from_airport"
                      checked={selected === "from_airport"}
                      onChange={handleChange}
                    />
                    <label
                      className={`btn ${
                        selected === "from_airport"
                          ? "btn-success"
                          : "btn-default"
                      }`}
                      htmlFor="from_airport"
                    >
                      <span>FROM AIRPORT</span>
                    </label>
                  </div>
                  <div className="to">
                    <input
                      type="radio"
                      id="to_airport"
                      name="service_type"
                      value="to_airport"
                      checked={selected === "to_airport"}
                      onChange={handleChange}
                    />
                    <label
                      className={`btn ${
                        selected === "from_airport"
                          ? "btn-default"
                          : "btn-success"
                      }`}
                      htmlFor="to"
                    >
                      <span>TO AIRPORT</span>
                    </label>
                  </div>
                </div>

                {/* Pickup Address Input */}
                <div className="field-wrapper start-form-wrapper">
                  <div className="input-group border-2 border-dark py-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text mt-1">
                        <div className="circle-icon-green">
                          <FaCircle size={10} color="white" />
                        </div>
                      </span>
                    </div>
                    <div className="w-75">
                      <Autocomplete
                        onLoad={(ref) => (autocompletePickup.current = ref)}
                        onPlaceChanged={() =>
                          handlePlaceChanged(autocompletePickup, setPickup)
                        }
                      >
                        <input
                          type="text"
                          className="form-control br-0 inputstyle"
                          placeholder="Pickup"
                          value={
                            selected === "from_airport"
                              ? fixedInputValue
                              : pickup.address || ""
                          }
                          onChange={(e) =>
                            dispatch(
                              setPickup({ ...pickup, address: e.target.value })
                            )
                          }
                          onKeyDown={handleKeyDown}
                        />
                      </Autocomplete>
                    </div>
                  </div>
                </div>

                {/* Dropoff Address Input */}
                <div className="input-group border-2 border-dark py-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text mt-1">
                      <div className="circle-icon-red">
                        <FaCircle size={10} color="white" />
                      </div>
                    </span>
                  </div>
                  <div className="w-75">
                    <Autocomplete
                      onLoad={(ref) => (autocompleteDropoff.current = ref)}
                      onPlaceChanged={() =>
                        handlePlaceChanged(autocompleteDropoff, setDropoff)
                      }
                    >
                      <input
                        type="text"
                        className="form-control br-0 inputstyle"
                        placeholder="Dropoff"
                        value={
                          selected !== "from_airport"
                            ? fixedInputValue
                            : dropoff.address || ""
                        }
                        onChange={(e) =>
                          dispatch(
                            setDropoff({ ...dropoff, address: e.target.value })
                          )
                        }
                        onKeyDown={handleKeyDown}
                      />
                    </Autocomplete>
                  </div>
                </div>

                <div className="fix-btn-bg bg-success">
                  <div className="d-flex justify-content-between mb-1 ">
                    <button
                      className="btn-main w-full m-auto bg-transparent border-0 text-white py-3 outline-0 fw-bold"
                      onClick={(e) => {
                        navigate("/calender");
                        e.preventDefault();
                      }}
                    >
                      REQUEST A RIDE
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-lg-7">
          <Map
            direction={direction}
            pickup={pickup.coordinates}
            dropoff={dropoff.coordinates}
          />
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
