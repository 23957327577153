import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSuitcase } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bookingInfo } from "../../api/api.js";
import loader from "../../assets/images/loader.gif";
import {
  selectFleet,
  setFleetRecordWithRateId,
  setMapsRecordId,
  setQuoteInfoRecordId,
  setFleetSelection,
  setSelectedVehicleId,
} from "../../features/bookingSlice/bookingSlice";

const RightSideCars = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // Loader state

  const fleet_id = useSelector((state) => state.booking.fleet_id);
  const bookingData = useSelector((state) => state.booking);

  const handleBookingRequest = async () => {
    if (!bookingData) {
      console.error("No booking data found in Redux store");
      return;
    }

    const requestData = {
      fleet_id: bookingData.fleet_id || "",
      fleet_record_with_rate_id: bookingData.fleet_record_with_rate_id || "",
      maps_record_id: bookingData.maps_record_id || "",
      quote_info_record_id: bookingData.quote_info_record_id || "",
    };

    try {
      setLoading(true);
      const response = await bookingInfo(requestData);
      console.log(response);

      dispatch(setSelectedVehicleId(response.data.selected_vehicle_record_id));

      navigate("/vehicleextra");
    } catch (error) {
      console.error("Error processing booking request:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data?.fleets?.length > 0 && !fleet_id) {
      dispatch(selectFleet(data.fleets[0].fleet_id));
      dispatch(setFleetRecordWithRateId(data.fleet_record_with_rate_id));
      dispatch(setMapsRecordId(data.maps_record_id));
      dispatch(setQuoteInfoRecordId(data.quote_info_record_id));
    }
  }, [data]);

  // ✅ Function to handle selection
  const selectVehicle = (fleet) => {
    dispatch(selectFleet(fleet.fleet_id));
    dispatch(setFleetSelection(fleet));
  };

  if (!data?.fleets) return <p>Loading...</p>;

  return (
    <div className="right-side-cars w-full">
      {loading && (
        <div className="full-page-loader">
          <img src={loader} width={"200px"} alt="Loading..." />
        </div>
      )}
      <div className="header-section">
        <h2>SELECT CAR</h2>
        <p className="subtitle">
          Select your desired car according to your requirements.
        </p>
      </div>

      <div className="fleet-list">
        {data.fleets.map((fleet) => (
          <div
            key={fleet.fleet_id}
            className={`fleet-card ${
              fleet_id === fleet.fleet_id ? "active" : ""
            }`}
            onClick={() => selectVehicle(fleet)}
          >
            <div className="fleet-header">
              <h4 className="title">
                {fleet.info.name}
                <span className="fleet-icons">
                  <FontAwesomeIcon icon={faUser} size="xs" /> 2
                  <FontAwesomeIcon icon={faSuitcase} size="xs" /> 2
                </span>
              </h4>
              <img
                width="150px"
                src={fleet.info.img_link}
                alt={fleet.info.img_name}
              />
              <p className="fleet-description">{fleet.info.desc}</p>
            </div>
            <div className="price">
              ${fleet?.fare_details.total_fare.toFixed(2)}
            </div>
          </div>
        ))}
      </div>

      <button className="proceed-button" onClick={handleBookingRequest}>
        PROCEED
      </button>

      <style jsx>{`
        .right-side-cars {
          padding: 20px;
          background: #fff;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        .fleet-list {
          max-height: 62vh;
          overflow-x: hidden;
          overflow-y: scroll;
        }
        .header-section {
          border-bottom: 1px solid #eee;
          padding-bottom: 15px;
          margin-bottom: 20px;
        }

        h2 {
          color: #333;
          margin-bottom: 8px;
        }

        .subtitle {
          color: #666;
          font-size: 0.9em;
          line-height: 1.4;
        }

        .fleet-card {
          margin-bottom: 20px;
          padding: 15px;
          border: 1px solid #eee;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }

        /* ✅ Fix: Active class styles */
        .fleet-card.active {
          border: 2px solid #229558;
          background: #e6f9f0;
          transform: scale(1.01);
        }

        .fleet-card:hover {
          border-color: #229558;
        }

        .fleet-card {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }

        .price {
          color: #229558;
          font-weight: bold;
          font-size: 20px;
        }

        .fleet-description {
          color: #666;
          font-size: 0.9em;
          line-height: 1.4;
          margin: 0;
        }

        .proceed-button {
          width: 100%;
          padding: 12px;
          background: #229558;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 1em;
          margin-top: 20px;
          cursor: pointer;
        }

        .proceed-button:hover {
          transform: scale(1.01);
        }
      `}</style>
    </div>
  );
};

export default RightSideCars;
