import "./style.css";
import LeftSide from "./components/LeftSide";
import RightSideCars from "./components/RightSideCars.jsx";
import { fetchQuote } from "../api/api.js";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setServiceType,
  setPickup,
  setDropoff,
  setDate,
  setTime,
  setPassenger,
  setLuggage,
  setBooster,
  setRegular,
  setInfant,
} from "../features/bookingSlice/bookingSlice"; // Import Redux actions
import { useNavigate } from "react-router-dom";
import loader from "../assets/images/loader.gif";

const QuotePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookingData = useSelector((state) => state.booking);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  // Extract all query parameters from the URL and build the proper data structure
  const extractQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      service_type: params.get("service_type") || "from_airport",
      pickup: {
        address: params.get("from") || "Boston Logan International Airport",
        coordinates: {
          lat: parseFloat(params.get("start_lat")) || 0,
          lng: parseFloat(params.get("start_lng")) || 0,
        },
      },
      dropoff: {
        address: params.get("to") || "Boston Logan International Airport",
        coordinates: {
          lat: parseFloat(params.get("end_lat")) || 0,
          lng: parseFloat(params.get("end_lng")) || 0,
        },
      },
      date: params.get("pick_date") || "",
      time: params.get("pick_time") || "",
      passenger: Number(params.get("passengers")) || 1,
      luggage: Number(params.get("luggage")) || 1,
      infant: params.get("infant_seat") || "0",
      regular: params.get("regular_seat") || "0",
      booster: params.get("booster_seat") || "0",
    };
  };

  // Dispatch the extracted query parameters to Redux
  const updateReduxWithQueryParams = (queryData) => {
    dispatch(setServiceType(queryData.service_type));
    dispatch(setPickup(queryData.pickup));
    dispatch(setDropoff(queryData.dropoff));
    dispatch(setDate(queryData.date));
    dispatch(setTime(queryData.time));
    dispatch(setPassenger(queryData.passenger));
    dispatch(setLuggage(queryData.luggage));
    dispatch(setInfant(queryData.infant));
    dispatch(setRegular(queryData.regular));
    dispatch(setBooster(queryData.booster));
  };

  // Build the API request data using Redux values (falling back to query values)
  const handleFetchQuote = async (queryData) => {
    const requestData = {
      booster_seat: bookingData.booster || queryData.booster || "0",
      domain_id: bookingData.domain_id || "",
      end: bookingData.dropoff?.address || queryData.dropoff.address || "",
      end_lat:
        bookingData.dropoff?.coordinates?.lat ||
        queryData.dropoff.coordinates.lat ||
        0,
      end_lng:
        bookingData.dropoff?.coordinates?.lng ||
        queryData.dropoff.coordinates.lng ||
        0,
      infant_seat: bookingData.infant || queryData.infant || "0",
      luggage: bookingData.luggage || queryData.luggage || "0",
      passengers: bookingData.passenger || queryData.passenger || "1",
      pick_date: bookingData.date || queryData.date || "",
      pick_time: bookingData.time || queryData.time || "",
      regular_seat: bookingData.regular || queryData.regular || "0",
      service_type: bookingData.service_type || queryData.service_type || "",
      start: bookingData.pickup?.address || queryData.pickup.address || "",
      start_lat:
        bookingData.pickup?.coordinates?.lat ||
        queryData.pickup.coordinates.lat ||
        0,
      start_lng:
        bookingData.pickup?.coordinates?.lng ||
        queryData.pickup.coordinates.lng ||
        0,
    };

    try {
      setLoading(true);
      const response = await fetchQuote(requestData);
      setData(response.data);
      console.log("Quote Response:", response.data);
      if (response.data) {
        navigate("/vehicleselection");
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryData = extractQueryParams();
    updateReduxWithQueryParams(queryData);
    handleFetchQuote(queryData);
  }, []);

  return (
    <div
      className={`container-fluid dim-bg main-quote-page right-side-cars ${
        loading ? "loading-bg" : ""
      }`}
    >
      {loading && (
        <div className="full-page-loader">
          <img src={loader} width={"200px"} alt="Loading..." />
        </div>
      )}
      <div className="row flex min-h-[80vh] w-full shadow-xl p-6 bg-white relative">
        {/* Uncomment or update LeftSide / RightSideCars as needed */}
        <div className="col-md-12 d-flex align-center justify-between">
           <p>Please wait while fetching the data</p>         
        </div>
        
      </div>
    </div>
  );
};

export default QuotePage;
