import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { finishBooking } from "../api/api";
import loader from "../assets/images/loader.gif";
import { resetBooking } from "../features/bookingSlice/bookingSlice";
import { FaFilePdf, FaPrint } from "react-icons/fa";

const ThankYouPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const apiCalled = useRef(false); // Prevent duplicate API calls

  // Get booking data from Redux store
  const booking_id = useSelector((state) => state.booking.booking_id);
  const quote_info_record_id = useSelector(
    (state) => state.booking.quote_info_record_id
  );
  const selected_vehicle_id = useSelector(
    (state) => state.booking.selected_vehicle_id
  );

  useEffect(() => {
    const completeBooking = async () => {
      if (apiCalled.current || !booking_id) return; // Prevent duplicate calls
      apiCalled.current = true;

      try {
        const finalData = {
          booking_info_record_id: booking_id,
          quote_info_record_id,
          selected_vehicle_record_id: selected_vehicle_id,
        };

        const response = await finishBooking(finalData);
        setMessage(response.message);
        setBookingId(response.data.booking_ref_id); // Set the booking reference ID
        if (response.status === 1) {
          dispatch(resetBooking());
        }
      } catch (err) {
        console.error("Error finishing booking:", err);
      } finally {
        setLoading(false);
      }
    };

    completeBooking();
  }, [booking_id, dispatch, quote_info_record_id, selected_vehicle_id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={loader} alt="Loading..." width="200px" />
      </div>
    );
  }

  return (
    <>
      <style>{`
        .btn-main {
          background: #188038;
          border: 0;
          border-radius: 0;
          color: #fff;
          display: block;
          font-size: 16px;
          font-weight: 800;
          letter-spacing: 0.2px;
          padding: 15px 30px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          width: 300px;
          text-align: center;
          margin: auto;
      }
      `}</style>
      <div className="container-fluid dim-bg thankyou-page" >
        <div className="floating-sidebar text-center p-[100px] whitespace-normal" style={{ minHeight: "unset", maxHeight: "500px"}}>
          <h1 className="font-extrabold text-4xl mb-4 "  style={{ fontWeight: 800 }}>Thank You!</h1>
          <p>
            {message ? message : "Sorry payment not successful."}
          </p>
          <p className="inline-block">
            You can{" "}
            <a
              className="pdf-section inline"
              href={`https://dispatch.gologanairport.com/api/quote/download-pdf?booking_ref_id=${bookingId}`}
            >
              <FaFilePdf className="inline" /> download the pdf here.
            </a>{" "}
            or{" "}
            <a
              className="pdf-section inline"
              href={`https://dispatch.gologanairport.com/api/quote/download-pdf?booking_ref_id=${bookingId}&print=1`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPrint className="inline" /> Print the invoice.
            </a>
          </p>
          <div className="block mt-4">
            <a className="btn-main" href="/">
              Back to Homepage
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouPage;
