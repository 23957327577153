import "./style.css";
import LeftSide from "./components/LeftSide";
import RightSideCars from "./components/RightSideCars.jsx";
import { fetchQuote } from "../api/api.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import loader from "../assets/images/loader.gif";

const ThirdStep = () => {
  const bookingData = useSelector((state) => state.booking);
  const [loading, setLoading] = useState(false); // Loader state
  const [data, setData] = useState(null); // Store API response

  // Function to fetch the quote
  const handleFetchQuote = async () => {
    if (!bookingData) {
      console.error("No booking data found in Redux store");
      return;
    }

    const requestData = {
      booster_seat: bookingData.booster || "0",
      domain_id: bookingData.domain_id || "",
      end: bookingData.dropoff?.address || "",
      end_lat: bookingData.dropoff?.coordinates?.lat || 0,
      end_lng: bookingData.dropoff?.coordinates?.lng || 0,
      infant_seat: bookingData.infant || "0",
      luggage: bookingData.luggage || "0",
      passengers: bookingData.passenger || "1",
      pick_date: bookingData.date || "",
      pick_time: bookingData.time || "",
      regular_seat: bookingData.regular || "0",
      service_type: bookingData.service_type || "",
      start: bookingData.pickup?.address || "",
      start_lat: bookingData.pickup?.coordinates?.lat || 0,
      start_lng: bookingData.pickup?.coordinates?.lng || 0,
    };

    try {
      setLoading(true); 
      const response = await fetchQuote(requestData);
      setData(response.data);
      console.log("Quote Response:", response);
    } catch (error) {
      console.error("Error fetching quote:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Call API when component mounts
  useEffect(() => {
    handleFetchQuote();
  }, []);

  return (
    <div className={`container-fluid dim-bg main-quote-page right-side-cars ${loading ? "loading-bg" : ""}`}>
      {loading && (
        <div className="full-page-loader">
          <img src={loader} width={'200px'} alt="Loading..." />
        </div>
      )}
      <div className="row flex min-h-[80vh] w-full shadow-xl p-6 bg-white relative">
      <div className="col-md-6">
        <LeftSide />
      </div>
      <div className="col-md-6">
        {!loading && <RightSideCars data={data} />}
      </div>
      </div>
    </div>
  );
  
};

export default ThirdStep;
