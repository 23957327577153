import React, { useRef, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';

// Default location: Boston Logan International Airport (BOS)
const defaultCenter = {
  lat: 42.3656,  // Latitude of Boston Logan International Airport
  lng: -71.0096, // Longitude of Boston Logan International Airport
};

const Map = ({ direction, pickup, dropoff }) => {
  const mapRef = useRef(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"]
  });

  useEffect(() => {
    if (pickup && dropoff) {
      // If both pickup and dropoff are set, center the map between them
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(pickup.lat, pickup.lng));
      bounds.extend(new window.google.maps.LatLng(dropoff.lat, dropoff.lng));
      mapRef.current?.fitBounds(bounds);
    } else if (pickup) {
      setMapCenter({ lat: pickup.lat, lng: pickup.lng });
    } else if (dropoff) {
      setMapCenter({ lat: dropoff.lat, lng: dropoff.lng });
    } else {
      setMapCenter(defaultCenter);
    }
  }, [pickup, dropoff]);

  const handleMapLoad = (map) => {
    mapRef.current = map;
    map.setZoom(12);
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  if (!isLoaded) {
    return (
      <div style={{ height: "70vh" }}>
        <div className="card h-100" aria-hidden="true">
          <div className="card-body" style={{ height: "70vh" }}>
            <h5 className="card-title placeholder-glow h-100 w-100">
              <span className="placeholder col-6 h-100 w-100"></span>
            </h5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <GoogleMap 
      mapContainerStyle={containerStyle} 
      center={mapCenter}
      zoom={12}
      onLoad={handleMapLoad}
    >
      {/* Markers for Pickup and Dropoff */}
      {pickup && <Marker position={pickup} label="P" />}
      {dropoff && <Marker position={dropoff} label="D" />}

      {/* Directions Renderer */}
      {direction && <DirectionsRenderer directions={direction} />}
    </GoogleMap>
  );
};

export default Map;
